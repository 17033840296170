import React, { useEffect, useState, useRef} from 'react';
import { useNavigate } from 'react-router';
import { getCookieValue, postToNativeApp } from '../common/helpers';
import { beneficiariesAsync, getBeneficiaries } from '../reducers/beneficiaries.slice';
import {  useAppDispatch, useAppSelector } from "../app/hooks";
import { Insurer } from '../interfaces/beneficiaries.interface';
import { useSearchParams } from 'react-router-dom';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { getConfig } from '../reducers/config.slice';
interface IProps {
    API_KEY: string;
    BENEFICIARY: string;
    INSURER: string;
}
export default function NetworkHospital () {
    const navigate = useNavigate();
    const [networkData, setNetworkData] = React.useState(null);
    const beneficiariesResponse = useAppSelector(getBeneficiaries);
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');
    const dispatch = useAppDispatch();
    const webComponentRef = useRef(null);
    const corporateConfig = useAppSelector(getConfig);

    useEffect(() => {
        if (localStorage.getItem('platform') == 'android' && localStorage.getItem('lat') && localStorage.getItem('long')) { //take lat and long from medibuddy app 
            setNetworkData({ lat: localStorage.getItem('lat'), long: localStorage.getItem('long') })
        }
        if (getCookieValue("platform") == "ios") {
            window.addEventListener("message", handleMessage);
            return () => {
                window.removeEventListener("message", handleMessage);
            };
        } else if (getCookieValue("platform") == "android") {
            document.addEventListener("message", handleMessage);
            return () => {
                document.removeEventListener("message", handleMessage);
            };
        }
    }, []);
  
    useEffect(() => {
        if(!beneficiariesResponse){
        dispatch(beneficiariesAsync());
      }
    },[]);
  
    useEffect(() => {
        if(sessionStorage.getItem('InsurerList_NH')?.length) return;
      const insurerSet = new Set<number>();
      const insurerList = Array<Insurer>();
      beneficiariesResponse?.policyDetails.forEach((policyDetail) => {
        if (!insurerSet.has(policyDetail.policyInsCompId)) {
          const insurer = new Insurer();
          insurer.name = policyDetail.insuranceCompanyName;
          insurer.shortName = policyDetail.insuranceCompanyShortName;
          insurerList.push(insurer);
          insurerSet.add(policyDetail.policyInsCompId);
        }
      });
        if(insurerList?.length){
            sessionStorage.setItem('InsurerList_NH',JSON.stringify(insurerList));
      }
    }, [beneficiariesResponse]);
  
    const handleMessage = (event: any) => {
      const message = JSON.parse(event?.data);
      if (message.event === 'networkHospitalClick') {
            const messageRecieved = JSON.parse(message?.data);
            setNetworkData(messageRecieved)
      }
    };
  
    const initializeNetwokComp = () =>{
        const eventQuerry = document.querySelector("network-hospital-web-component")
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
        navigate(-1);
        })
    }
  
    useEffect(() => {
        if (getCookieValue('source') == 'native') {
            postToNativeApp({ "location": "getAndroidlocation" })
      }
        else if(localStorage.getItem('platform') != 'android') {  //platform is passed from medibuddy app in query params
            initializeNetwokComp()
        }
    }, [])
  
    useEffect(() => {
      if (networkData) {
            initializeNetwokComp()
        }
    }, [networkData])

    useEffect(() => {
        if (!webComponentRef.current) {
          webComponentRef.current = document.querySelector("network-hospital-web-component");
    
          webComponentRef.current?.addEventListener("searchHospital", (event: any) => {
            dataLayerPush(Constants.Instance.HOSPITAL_SEARCH, {
              keyword: event?.detail
            });
          });
          webComponentRef.current?.addEventListener("clickhospitalcard", (event: any) => {
              dataLayerPush(Constants.Instance.NETWORK_HOSPITAL_CTA_INTERACTION, {
                  cta_text:event?.detail[0],
                  section_name: event?.detail[1]  
              });
            });
        }
      }, []);

    const [apiKey, setApiKey] = useState<string>(process.env.REACT_APP_GMAP_API_KEY || "");
    
    if (getCookieValue('source') == 'native' || localStorage.getItem('platform') == 'android') {  //platform is passed from medibuddy app in query params
      if (networkData)
        return React.createElement('network-hospital-web-component', {
          theme: "theme1",
          locale: "en",
          accessToken: localStorage.getItem("accessToken") ?? "",
          lat: networkData?.lat,
          long: networkData?.long,
          insurerList: sessionStorage.getItem("InsurerList_NH") ?? '[]',
          source: localStorage.getItem('source'), //to hide header in medibuddy app
          hideIntimation: corporateConfig?.hideIntimation,
            })
    }
    else {
      return React.createElement('network-hospital-web-component', {
        theme: "theme1",
        locale: "en",
        accessToken: localStorage.getItem("accessToken") ?? "",
        insurerList: sessionStorage.getItem("InsurerList_NH") ?? '[]',
        source: localStorage.getItem('source'), //to hide header in medibuddy app
        hideIntimation: corporateConfig?.hideIntimation,
        })
    }
  }