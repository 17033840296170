import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {  policyBucketDetailsApi } from '../api/homeAPI';
import { BucketDetailsRequest, BucketDetailsResponse } from '../interfaces/policyBucket.interface';

export interface PolicyBucketDetailsState {
  value: BucketDetailsResponse | null;
  status: 'idle' | 'loading' | 'failed';
  loader: boolean;
}

const initialState: PolicyBucketDetailsState = {
  value: null,
  status: 'idle',
  loader: true,
};

export const policyBucketDetailsAsync = createAsyncThunk(
  'policyBucketDetails/policyBucketDetailsAsync',
  async (req: BucketDetailsRequest) => {
    const response = await policyBucketDetailsApi(req)
    return response.data;
  }
);

export const policyBucketDetailsSlice = createSlice({
  name: 'policyBucketDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(policyBucketDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(policyBucketDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
        state.loader = false;
      })
      .addCase(policyBucketDetailsAsync.rejected, (state) => {
        state.status = 'failed';
        state.loader = false;
      });
  },
});

export const { } = policyBucketDetailsSlice.actions;
export const getPolicyBucketDetails = (state: RootState) => state.policyBucketDetails.value;
export const isPolicyBucketDetailsLoading = (state: RootState) => state.policyBucketDetails.status === 'loading';
export const policyBucketDetailsLoader = (state: RootState) => state.policyBucketDetails.loader;

export default policyBucketDetailsSlice.reducer;
