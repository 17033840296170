import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { TicketRouteEnum, TicketTypeEnum } from "../constants/enum";
import { TicketHelpMessage, TicketResponseMessage } from "../interfaces/ticket.interface";
import { ContactUs, TicketMessage } from '@mediassistrepo/maven-old';
import { createTicket } from "../utils/ticket";
import { dataLayerPush } from "../utils/gtm";
import Constants from "../constants/Constants";
import { Helmet } from "react-helmet-async";
import { LinkPolicy } from '@mediassistrepo/link-policy';
import "@mediassistrepo/link-policy/dist/style.css";
export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}
export class sourceDetails {
    id?:number;
    header?: string;
    textFind?:string;
    textDescription?: string;
    textView?: string;
    textFullAccess?: string;
    isClaimRequest?:boolean;
    isEcardRequest ?: boolean;
    isIntimationRequest?:boolean;
    type?:string;
    sourceUrl?:string;
    fullAccessEnabled?:boolean;
}

function NewLinkPolicy(props: any) {
    const navigate = useNavigate();
    const search = window.location.search;
    const location = useLocation();
    const query = new URLSearchParams(search);
    const isEcardRequest = query.get('isEcardRequest');
    const isIntimationRequest = query.get('isIntimationRequest');
    const isClaimRequest = query.get('isClaimRequest');
    const token = props?.token || query.get('q');
    const [showTicket, setShowTicket] = React.useState<boolean>(false);
    const [userName, setUserName] = React.useState<string>(null);
    const [loginMethod, setLoginMethod] = React.useState<string>(null);
    const [error, setError] = React.useState<string>(null);
    const [lsKey, setLsKey] = React.useState<string>(null);
    const [types, setTypes] = React.useState<TicketTypeEnum>(null);
    const [ticketResponse, setTicketResponse] = React.useState<string>('');
    const [ticketRoute, setTicketRoute] = React.useState<any>(null);
    const [sourceData, setsourceData] = React.useState<sourceDetails>({
    });
    const [isHideTicketText, setIsHideTicketText] = React.useState<boolean>(false);


    useEffect(() => {
        let obj = new sourceDetails();
        obj.type = "linkPolicy";
        obj.isClaimRequest = (isClaimRequest && isClaimRequest === "true")?true:false;
        obj.isEcardRequest = (isEcardRequest && isEcardRequest === "true")?true:false;
        obj.isIntimationRequest = (isIntimationRequest && isIntimationRequest === "true")?true:false;
        obj.sourceUrl = location?.state?.pageName ?? '/';
        obj.fullAccessEnabled = true;
        if(location?.state?.data){
            obj.id= location?.state?.data?.id;
            obj.header= location?.state?.data?.header;
            obj.textFind= location?.state?.data?.textFind;
            obj.textDescription= location?.state?.data?.textDescription;
            obj.textView= location?.state?.data?.textView;
            obj.textFullAccess= location?.state?.data?.textFullAccess;
            setsourceData(obj);
        }else{
            obj.id= 6;
            obj.header= "Validate yourself";
            obj.textFind= "Find my policy";
            obj.textDescription= "";
            obj.textView= "View your policy";
            obj.textFullAccess= "Get full access";
            setsourceData(obj);
        }
        
    }, []);  
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleInputEvent = (value1: any,value2:any,value3:any) => {
        dataLayerPush(Constants.Instance.POLICY_FORM_FILL, {
            field_name: value1,
            form_type: value2,
            details_name: value3,            
        });
    };

    const handleCtaEvents = (value1: any,value2:any,value3:any) => {
        dataLayerPush(Constants.Instance.POLICY_FORM_CTA_INTERACTION,{
            cta_text : value1, 
            form_type : value2,
            details_name : value3,            
        })
    };

    const handlePopupEvents = (value1: any,value2:any) => {
        dataLayerPush(Constants.Instance.POLICY_POPUP_CTA_INTERACTION,{
            cta_text : value1,
            policy_type : value2,            
        })
    };

    const handleRaiseTicket =(detail:any)=>{
        if(detail){
        let username = localStorage.getItem("loginMobile") ? localStorage.getItem("loginMobile") : localStorage.getItem("loginEmail");
        if(userName){
            setUserName(username)
        }
        setShowTicket(true);
        setError(detail.error);
        setLsKey(detail.lsKey);
        setTypes(TicketTypeEnum[detail.type as keyof typeof TicketTypeEnum]);
        setTicketRoute(TicketRouteEnum[detail.route as keyof typeof TicketRouteEnum]);
        setIsHideTicketText(false);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>MAven - Link your policy</title>
                <meta name="description" content="" />
            </Helmet>
            <div>
                <LinkPolicy handleBackClick={handleBackClick} handleInputEvent={handleInputEvent} handleCtaEvents={handleCtaEvents} handlePopupEvents={handlePopupEvents} token={token} handleRaiseTicket={handleRaiseTicket} source={sourceData} isHideTicket ={isHideTicketText}
                />
                {showTicket && <ContactUs
                    type={types}
                    failureRoute={ticketRoute}
                    helpMessage={TicketHelpMessage.ValidationFailure}
                    createTicket={(type: TicketTypeEnum, failureRoute: TicketRouteEnum, email: string, lsKey: string) => createTicket(type, failureRoute, email, userName, error, loginMethod, lsKey).then(res => {
                        if (res?.isSuccess) {
                            setIsHideTicketText(true);
                            setShowTicket(false);
                            setTicketResponse(TicketResponseMessage.SuccessMessage);
                        } else {
                            setIsHideTicketText(false);
                            setTicketResponse(TicketResponseMessage.FailureMessage);
                        }
                        return res;
                    })}
                    lsKey={lsKey}
                    handleClose={() => setShowTicket(false)}
                />}
                {ticketResponse !== '' && <TicketMessage ticketMessage={ticketResponse} handleClose={() => { setTicketResponse(''); setShowTicket(false); }} />}
            </div>
        </React.Fragment>
    );
}

export default NewLinkPolicy;