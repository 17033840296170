import React, { useEffect, useState,useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { getConfig } from '../reducers/config.slice';
import Loader from './shared/Loader';
import SurveyMain from '../common/Survey/SurveyMain';
import Constants from '../constants/Constants';
import { dataLayerPush } from '../utils/gtm';
import { getBeneficiaries } from '../reducers/beneficiaries.slice';
import { FeedBack, SetNpsFeedBack } from '../api/homeAPI';
import { EntityValue, FeedbackValue, NpsFeedback } from '../common/Nps/nps.models';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import {NpsFeedbackClaimSubmission} from '@mediassistrepo/homepage-component';
import { ClaimSubmission as ClaimSubmissionComponent } from '@mediassistrepo/claim-submission';

import "@mediassistrepo/claim-submission/dist/root.css";
import { refreshTokenRequest } from '../utils/tokenService';


export default function ClaimSubmission(props: any) {
    const [loading, setLoading] = useState(false)
    const history = useNavigate();
    const params = useParams()
    const config = useAppSelector(getConfig);
    const submissionType = params.submissionType;
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const [buttonClick, setButtonClick] = React.useState(null);
    const [showFeedBack,setShowFeedBack] = React.useState<boolean>(false);
    const [clmRefNo,setClmRefNo]= React.useState<string>(''); 
    const [showNpsFeedBack,setShowNpsFeedBack] = React.useState<boolean>(false);
    const [surveyFeedBack,setSurveyFeedBack] = React.useState<boolean>(true);
    const [priBenefName,setPriBenefName]= React.useState<string>(''); 
    const [benefId,setbenefId]= React.useState<string>(''); 
    const [claimPriBenefId,setClaimPriBenefId]= React.useState<string>('');
    // States to keep track of remind later counts
    const [claimSubIpdCount, setClaimSubIpdCount] = useState(0);
    const [claimSubOpdCount, setClaimSubOpdCount] = useState(0);
    const [npsPriMaId, setNpsPriMaId] = useState(null);
    
    const baseProfile = useAppSelector(getBaseProfile);
    const beneficiaryDetails = useAppSelector(getBeneficiaries);

    const pribenef = beneficiaryDetails?.beneficiaryDetails.filter((x:any)=>x?.relationToPrimaryId === 1);
    
   
    const claimSubEntityType = submissionType === 'IPD' ? "CS_NPS_IPD" : submissionType === 'OPD' ? "CS_NPS_OPD" : "CS_NPS_OPD";
    const claimSubSource = submissionType === 'IPD' ? "nps_cs_ipd" : submissionType === 'OPD' ? "nps_cs_opd" : "nps_cs_opd";

    const selectMemberHandler = (e: any) => {
        dataLayerPush(Constants.Instance.SELECT_INSURED_MEMBER, {
          claim_type: e?.detail === "IPD" ? "Hospitalisation" : "Clinic / OPD",
        });
      };
  
      const ipdSubmissionCtaHandler = (e: any) => {
        dataLayerPush(Constants.Instance.SUBMISSIONS_IPD_CTA_INTERACTION, {
          cta_text: e.detail[0],
          section: e.detail[1],
        });
      };
    
    useEffect(() => {
      const goBackHandler = (e: any) => {
        history(-1);
      };
      const successHandler = (e: any) => {
        setClmRefNo(e?.detail?.ClaimReferenceNo);
        setShowFeedBack(true);
      };
      
      const feedbackHandler = (e: any) => {
        const benefIdDetail = `${e?.detail?.detail?.benefId}`;
        const claimPriMaIdDetail = `${e?.detail?.detail?.priMaId}`;
        const benefNameDetail = `${e?.detail?.detail?.benefName}`;
        const claimRefNoDetail = `${e?.detail?.detail?.claimReferenceNumber}`;

        setClmRefNo(claimRefNoDetail);
        setPriBenefName(benefNameDetail);
        setbenefId(benefIdDetail);
        setClaimPriBenefId(claimPriMaIdDetail);

        const claimSubEntity =
          submissionType === "IPD"
            ? "CS_NPS_IPD"
            : submissionType === "OPD"
            ? "CS_NPS_OPD"
            : "CS_NPS_OPD";
        fetchNpsClaimSubmissionApi(claimPriMaIdDetail, claimSubEntity);
      };
      const feedbackRemindLaterHandler = (e: any) => {
        claimSubmissionRemindLater(`${e?.detail?.priMaId}`);
        history("/");
      };
      const fetchFeedbackHandler = (e: any) => {
        fetchInitialData();
      };

      window.addEventListener("GO_BACK", goBackHandler);
      window.addEventListener("success", successHandler);
      window.addEventListener("feedback", feedbackHandler);
      window.addEventListener(
        "feedbackRemindLater",
        feedbackRemindLaterHandler
      );
      window.addEventListener("selectMember", selectMemberHandler);
      window.addEventListener("ipdSubmissionCta", ipdSubmissionCtaHandler);
      window.addEventListener("TOKEN_EXPIRED", refreshTokenRequest);
      window.addEventListener("fetch-feedback", fetchFeedbackHandler);

      return () => {
        window.removeEventListener("GO_BACK", goBackHandler);
        window.removeEventListener("success", successHandler);
        window.removeEventListener("feedback", feedbackHandler);
        window.removeEventListener(
          "feedbackRemindLater",
          feedbackRemindLaterHandler
        );
        window.removeEventListener("selectMember", selectMemberHandler);
        window.removeEventListener("ipdSubmissionCta", ipdSubmissionCtaHandler);
        window.removeEventListener("TOKEN_EXPIRED", refreshTokenRequest);
        window.removeEventListener("fetch-feedback", fetchFeedbackHandler);
      };
    }, [surveyFeedBack, submissionType, pribenef]);

    
    useEffect(() => {
        if(baseProfile)
        setNpsPriMaId(JSON.stringify(baseProfile?.data?.maid))
      }, [baseProfile]);
      
      const fetchInitialData = async () => {
       if (!npsPriMaId || npsPriMaId === "undefined") return;
       setLoading(true);
       try {
         const getFeedback = await FeedBack(npsPriMaId, claimSubEntityType);
         if (getFeedback?.data?.feedback_value) {
           const remindLaterCount = getFeedback?.data?.feedback_value?.remind_later?.count || 0;
           const feedbackKeys = Object.keys(getFeedback?.data?.feedback_value);
           if (feedbackKeys?.length > 1) {
             setSurveyFeedBack(false);
           } else {
             setSurveyFeedBack(!(remindLaterCount < 1));
           }} else if (getFeedback?.data?.statusCode === 204) {
            setSurveyFeedBack(false);
          } else {
            setSurveyFeedBack(true);
          }            
        } catch (err) {
          setSurveyFeedBack(true);
        } finally {
          setLoading(false);
        }                        
      };
        
      const remindLater = useCallback(async (
        type: string,
        setCount: React.Dispatch<React.SetStateAction<number>>,
        entityId: string
      ) => {
        setLoading(true);
        try {
          const response = await FeedBack(npsPriMaId, type);    
          let currentCount = response?.data?.feedback_value?.remind_later?.count || 0;
          const newCount = currentCount + 1;
          setCount(newCount);
          if (newCount > 1) {
            history('/');
            return;
          }
          const feedbackValue: FeedbackValue = { remind_later: { count: newCount } };
          const entityValue: EntityValue = { feedback_value: feedbackValue, feedback_rating: 0 };
          const requestObject: NpsFeedback = {
            source: claimSubSource,
            entity_Type: claimSubEntityType,
            entity_Id: entityId,
            entity_Value: entityValue
          };
          const updateResponse = await SetNpsFeedBack(requestObject);
          if (updateResponse?.data?.isSuccess) {
            history('/');
            setLoading(false);
          }
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }, [npsPriMaId, claimSubEntityType]);
    
    
    const claimSubmissionRemindLater = (entityId: string) => {
        remindLater(
            claimSubEntityType!,
            submissionType === 'IPD' ? setClaimSubIpdCount : setClaimSubOpdCount,
            entityId
        );
    };


    const fetchNpsClaimSubmissionApi = async (priMaId: string, entityType : string) => {
        if (!priMaId || priMaId === "undefined") {
          setLoading(false);
          return;
        }
        setLoading(true)
        try {
          const getFeedback: any = await FeedBack(priMaId, entityType);
          if (getFeedback?.data?.statusCode === 204 || getFeedback?.data?.feedback_value?.remind_later?.count < 1) {
            setShowNpsFeedBack(true);
          } else if (getFeedback?.data?.feedback_value) {
            setShowNpsFeedBack(false);
          }
        } catch (err) {
        } finally {
          setLoading(false);
        }
    };

    const feedback = async (data: { sliderValue: number; checkedStates: string[]; remarks: string }) => {

      let feedbackValue: Record<string, any> = {
        'Beneficiary name': priBenefName,
        'Primary beneficiary MA ID': npsPriMaId,
        'Claim ref no': clmRefNo,
        'Mobile': `${baseProfile?.data?.mobileNo}`,
        'Email ID': `${baseProfile?.data?.emailId}`,
        'Do you have any suggestions for improving the claim submission process?': data.remarks
      };
      let feedbackRating = data.sliderValue;
    
      if (data.sliderValue >= 8) {
        feedbackValue["What did you like most about the claim submission process?"] = data.checkedStates;
      } else if (data.sliderValue > 0 && data.sliderValue < 8) {
        feedbackValue["What did you like least about the claim submission process?"] = data.checkedStates;
      }
    
      let entityValue: EntityValue = new EntityValue();
      entityValue.feedback_rating = feedbackRating;
      entityValue.feedback_value = feedbackValue;
  
      let requestObject: any = {
        source: claimSubSource,
        entity_Type: claimSubEntityType,
        entity_Id: npsPriMaId,
        entity_Value: entityValue
      };
  
      try {
        const response = await SetNpsFeedBack(requestObject);
        if (response?.data && response?.data?.isSuccess) {
            setShowNpsFeedBack(true);
        } else {
        }
      } catch (err) {
      }
    };

    
    useEffect(() => {
        if (showNpsFeedBack) {
        document.body.classList.add("overflow-hidden");
        } else {
        document.body.classList.remove("overflow-hidden");
        }
        return () => {
        document.body.classList.remove("overflow-hidden");
        };
    }, [showNpsFeedBack]);


    return (
        <>
            {loading && <Loader />}
            
            {surveyFeedBack && showFeedBack && (
                <div className="relative">
                    <SurveyMain pageType="Claim Submission" pageTitle='The claim has been submitted successfully.' Id={clmRefNo} source={"clmsubmit"} entity_Type={"CLM_SUB"} closeFeedbackModal={() => setShowFeedBack(!showFeedBack)} />
                </div>
            )}

            {showNpsFeedBack ?
                <>
                    <div className="relative">
                        <NpsFeedbackClaimSubmission
                            Id={clmRefNo}
                            benefName={priBenefName}
                            benefId={benefId}
                            priBenefId={claimPriBenefId}
                            source={claimSubSource}
                            entity_Type={claimSubEntityType}
                            remindLater={() => claimSubmissionRemindLater(npsPriMaId)} 
                            onSubmit={feedback}
                        />
                    </div> 
                </>:
                <>
                    {pribenef && (
                      <ClaimSubmissionComponent
                      handleBackClick={() => history(-1)}
                      accessToken={localStorage.getItem("accessToken")}
                      claimType={submissionType}
                      benefitsType={query.get("benefitsType")}
                      homePage={query.get("homePage")}
                      event={buttonClick}
                      isGeneric={config?.isGenericClaimSubmission ? "true" : null}
                      priBenef={JSON.stringify(pribenef)}
                      />
                    )}
                </>
            }
        </>
    )
}