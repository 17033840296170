export enum TicketTypeEnum {
    PolicyNumber = 'POLICY_NUMBER',
    CorporateEmployee = 'CORPORATE_EMPLOYEE',
    ClaimId = 'CLAIM_ID',
    ContactId = "CONTACT_ID",
    LoginFailure = 'LOGIN_FAILURE',
    NotMyAccount = 'NOT_MY_ACCOUNT'
 }

 export enum TicketRouteEnum {
    WhatsappVerify = 'WHATSAPP_VERIFY',
    WhatsappIdentify = 'WHATSAPP_IDENTIFY',
    AuthSignin = 'AUTH_SIGNIN'
}