import { BaseResponse } from "./claimList.interface";

export interface BucketDetail {
    typeOfPolicy: string;
    type: string;
    si: number;
    spent: number;
    authorized: number;
    bsi: number
}

export interface BucketDetailsResponse extends BaseResponse{
    bucketDetails: BucketDetail[];
}

export class BucketDetailsRequest {
    polId: number;
}