import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { claimListApi } from '../api/claimListApi';
import { RootState } from '../app/store';
import { ClaimListRequest, ClaimListResponse } from '../interfaces/claimList.interface';
import { DeniedClaimListStatus } from '../common/common.constants';

export interface ClaimListState {
  value: ClaimListResponse | null;
  status: 'idle' | 'loading' | 'failed';
  freshClaimListCount: Number | 0;
}

const initialState: ClaimListState = {
  value: null,
  status: 'idle',
  freshClaimListCount: 0,
};

export const claimListAsync = createAsyncThunk(
  'claimList/claimListAsync',
  async (request: ClaimListRequest) => {
    const response = await claimListApi(request)
    return response.data;
  }
);
  

export const claimListSlice = createSlice({
  name: 'claimList',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
    resetToInitial: (state) =>{
      state.value = null;
      state.status = 'idle';
      state.freshClaimListCount = 0;
    }
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(claimListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(claimListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
        if(!state.freshClaimListCount){
          state.freshClaimListCount = action.payload.searchResults?.filter((x)=>!(DeniedClaimListStatus.includes(x?.status?.toLowerCase())))?.length;
        }
      })
      .addCase(claimListAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { resetToInitial } = claimListSlice.actions;
export const getClaimList = (state: RootState) => state.claimList.value;
export const getClaimListLoading = (state: RootState) => state.claimList.status === "loading";
export const getFreshRequestClaimList = (state:RootState) => state.claimList.freshClaimListCount;

export default claimListSlice.reducer;
